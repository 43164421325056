import {ListItem, ListItemText} from "@barracuda/bds-core";
import classNames from "classnames";
import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import {StyledComponentProps} from "@cuda-react/theme";

const workspaceListItemStyles = (theme: Theme) => createStyles({
    listItemContainer: {
        padding: theme.spacing(0)
    },
    listItem: {
        borderRadius: 4,
        '&:hover': {
            backgroundColor: '#E4F4FF', // TODO: Does this need tokenizing?
        },
        height: 40,
        marginLeft: theme.spacing(0.25),
        width: "calc(100% - 10px)",
        paddingTop: theme.spacing(0.5, 1, 0.5, 1)
    },
    listItemActive: {
        backgroundColor: '#E4F4FF', // TODO: Does this need tokenizing?
        color: '#0A5D91 !important', // TODO: Does this need tokenizing?
    },
    listItemName: {
        marginRight: 'auto',
        fontSize: 13,
        height: 16,
        lineHeight: '15px',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    listItemDescription: {
        marginRight: 'auto',
        fontSize: 11,
        height: 15,
        lineHeight: '13px',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
});
const useStyles = makeStyles(workspaceListItemStyles);

interface WorkspaceListItemProps extends StyledComponentProps<typeof workspaceListItemStyles> {
    workspace: { name: string, description?: string, id?: string | number },
    setWorkspace: (id: string | number) => void;
    active?: boolean;
}

export const WorkspaceListItem = (props: WorkspaceListItemProps) => {
    const {workspace, setWorkspace, active} = props;
    const classes = useStyles(props);

    return (
        <li className={classes.listItemContainer}>
            <ListItem
                key={workspace.id}
                onClick={() => {
                    workspace.id && setWorkspace(workspace.id);
                }}
                id={workspace.id?.toString()}
                button
                className={classNames(classes.listItem, active && classes.listItemActive)}
            >
                <ListItemText
                    classes={{
                        primary: classes.listItemName,
                        secondary: classes.listItemDescription
                    }}
                    primary={workspace.name}
                    primaryTypographyProps={{title: workspace.name}}
                    secondary={workspace.description}
                    secondaryTypographyProps={{title: workspace.description}}
                />
            </ListItem>
        </li>
    );
};

export default WorkspaceListItem;